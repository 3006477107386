import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-escondido-california.png'
import image0 from "../../images/cities/scale-model-of-balloon-safari-in-escondido-california.png"
import image1 from "../../images/cities/scale-model-of-muffler-man-in-escondido-california.png"
import image2 from "../../images/cities/scale-model-of-africa-tram-in-escondido-california.png"
import image3 from "../../images/cities/scale-model-of-north-san-diego-(sikes-adobe)-certified-farmers-market-in-escondido-california.png"
import image4 from "../../images/cities/scale-model-of-san-diego-zoo-safari-park-in-escondido-california.png"
import image5 from "../../images/cities/scale-model-of-grape-day-park-in-escondido-california.png"
import image6 from "../../images/cities/scale-model-of-escondido-history-center-in-escondido-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Escondido'
            state='California'
            image={image}
            lat='33.1331'
            lon='-117.074'
            attractions={ [{"name": "Balloon Safari", "vicinity": "San Diego Zoo Safari Park, 15500 San Pasqual Valley Rd, Escondido", "types": ["point_of_interest", "establishment"], "lat": 33.0970978, "lng": -116.99797309999997}, {"name": "Muffler Man", "vicinity": "302 E Valley Pkwy, Escondido", "types": ["point_of_interest", "establishment"], "lat": 33.1243917, "lng": -117.0793271}, {"name": "Africa Tram", "vicinity": "San Diego Zoo Safari Park, 15500 San Pasqual Valley Rd, Escondido", "types": ["point_of_interest", "establishment"], "lat": 33.09590740000001, "lng": -116.99742550000002}, {"name": "North San Diego (Sikes Adobe) Certified Farmers Market", "vicinity": "12655 Sunset Drive, at the Sikes Adobe Historic Farmstead, Escondido", "types": ["grocery_or_supermarket", "food", "store", "point_of_interest", "establishment"], "lat": 33.06731600000001, "lng": -117.067182}, {"name": "San Diego Zoo Safari Park", "vicinity": "15500 San Pasqual Valley Rd, Escondido", "types": ["zoo", "park", "point_of_interest", "establishment"], "lat": 33.0974458, "lng": -116.99572269999999}, {"name": "Grape Day Park", "vicinity": "321 N Broadway, Escondido", "types": ["park", "point_of_interest", "establishment"], "lat": 33.1239539, "lng": -117.08338120000002}, {"name": "Escondido History Center", "vicinity": "321 N Broadway, Escondido", "types": ["library", "museum", "point_of_interest", "establishment"], "lat": 33.1247311, "lng": -117.083054}] }
            attractionImages={ {"Balloon Safari":image0,"Muffler Man":image1,"Africa Tram":image2,"North San Diego (Sikes Adobe) Certified Farmers Market":image3,"San Diego Zoo Safari Park":image4,"Grape Day Park":image5,"Escondido History Center":image6,} }
       />);
  }
}